/*  ==========================================================================
    App Styles

    Configuration     - Configuration of Priva Styles
    Main              - Priva Styles
    Overrides         - Temporary Design System overrides
    Extensions        - App specific global styling

    DO      -   Divide styling into logical semantic folders and partials.
            -   Try to avoid adding unstructured "shame" styling.

    DON'T   -   Add any style declarations to this file.
            -   Add @import statements to this file.
    ========================================================================== */

/* Configuration */
@import 'configuration/configuration';

/* Main */
@import 'priva';

/* Overrides */
@import 'overrides/overrides';

/* Extensions */
@import 'extensions/extensions';

/* Internal variables */
@import 'variables';

.tile__space {
    width: 100%;
    height: 4px;
}

.employee-data,
.activity-data {
    &__edit-mode {
        flex: 0 0 auto;
    }

    &__form {
        flex: 1 1 grid-unit(40);
        max-width: 100%;
    }

    &__form-section {
        display: flex;
        flex-direction: column;
        gap: grid-unit(2);

        & + & {
            margin-top: grid-unit(4);
        }
    }

    &__form-group {
        width: grid-unit(50);
        max-width: 100%;
    }

    &__form-control {
        width: 100%;
    }
}
