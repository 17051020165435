/*  ==========================================================================
    Notifications
    ========================================================================== */

$notification-height: $component-size-base;
$notification-border-width: 1px;
$notification-padding-vertical: #{(($notification-height - $line-height-base-computed) * 0.5) -
    $notification-border-width};
$notification-padding-horizontal: grid-unit();
$notification-padding: $notification-padding-vertical $notification-padding-horizontal;
$notification-margin: grid-unit();
$notification-border-radius: $border-radius-small;

/* Emphasis */
$notification-message-margin: grid-unit();

/* Status icon */
$notification-status-icon-margin: grid-unit();
$notification-status-icon-font-size: $icon-font-size-base;
$notification-status-icon-line-height: $line-height-computed;

/* Close icon */
$notification-close-width: $notification-height;
$notification-close-height: $notification-height;
$notification-close-line-height: $component-size-sm;
$notification-close-padding: (($notification-height - $notification-close-line-height) * 0.5);
$notification-close-font-size: $icon-font-size-base;
$notification-large-close-offset: grid-type-unit();

/* Content */
$notification-link-text-decoration: underline;
$notification-divider-margin: grid-unit(3) 0;
$notification-paragraph-margin: grid-unit(2);

/* large */
$notification-large-height: $touch-area;
$notification-large-padding-vertical: (
        ($notification-large-height - $line-height-computed) - ($notification-border-width * 2)
    ) *
    0.5; // ensure minimum height equal to $touch-area
$notification-large-padding-horizontal: grid-type-unit(4);
$notification-large-padding: $notification-large-padding-vertical $notification-large-padding-horizontal;
$notification-large-close-line-height: $touch-area - grid-unit(2);

/*  Styles
    ========================================================================== */
$notification-info-text: $color-info-text;
$notification-info-bg: $color-info-background;
$notification-info-border: $color-info-border;
$notification-info-close-color: $notification-info-text;
$notification-info-close-hover-color: $notification-info-border;
$notification-info-close-pressed-color: $color-info;

$notification-success-text: $color-success-text;
$notification-success-bg: $color-success-background;
$notification-success-border: $color-success-border;
$notification-success-close-color: $notification-success-text;
$notification-success-close-hover-color: $notification-success-border;
$notification-success-close-pressed-color: $color-success;

$notification-warning-text: $color-warning-text;
$notification-warning-bg: $color-warning-background;
$notification-warning-border: $color-warning-border;
$notification-warning-close-color: $notification-warning-text;
$notification-warning-close-hover-color: $notification-warning-border;
$notification-warning-close-pressed-color: $color-warning;

$notification-danger-text: $color-danger-text;
$notification-danger-bg: $color-danger-background;
$notification-danger-border: $color-danger-border;
$notification-danger-close-color: $notification-danger-text;
$notification-danger-close-hover-color: $notification-danger-border;
$notification-danger-close-pressed-color: $color-danger;

/*  Legacy
    ========================================================================== */
$notification-info-close-hover-bg: $color-info-hover-background;
$notification-info-close-pressed-bg: $color-info-pressed-background;
$notification-success-close-hover-bg: $color-success-hover-background;
$notification-success-close-pressed-bg: $color-success-pressed-background;
$notification-warning-close-hover-bg: $color-warning-hover-background;
$notification-warning-close-pressed-bg: $color-warning-pressed-background;
$notification-danger-close-hover-bg: $color-danger-hover-background;
$notification-danger-close-pressed-bg: $color-danger-pressed-background;
