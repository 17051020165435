/*  ==========================================================================
    Selection Group
    ========================================================================== */

/* buttons */
$selection-group__button_min-height: $component-size-base;
$selection-group__button_min-width: $component-size-base;
$selection-group__button_line-height: $font-size-lg;
$selection-group__button_border-size: $border-width-light;
$selection-group__button_padding-vertical: (
        ($selection-group__button_min-height - $selection-group__button_line-height) * 0.5
    ) -
    $selection-group__button_border-size;
$selection-group__button_padding-horizontal: grid-unit(1);
$selection-group__button_padding: $selection-group__button_padding-vertical
    $selection-group__button_padding-horizontal;
$selection-group__button_border-radius: $border-radius;
$selection-group__button_border-color: transparent;
$selection-group__button_color: $color-selected;

/* icon */
$selection-group__prefix_margin: grid-unit();

/*  Sizes
    ========================================================================== */

/* Small */
$selection-group--size-sm_spacing: grid-type-unit();
$selection-group--size-sm__item_margin: 0 $selection-group--size-sm_spacing $selection-group--size-sm_spacing
    0;
$selection-group--sm__button_min-height: $component-size-sm;
$selection-group--sm__button_min-width: $component-size-sm;
$selection-group--sm__button_font-size: $font-size-sm;
$selection-group--sm__button_line-height: $font-size-md;
$selection-group--sm__button_padding-vertical: (
        ($selection-group--sm__button_min-height - $selection-group--sm__button_line-height) * 0.5
    ) -
    $selection-group__button_border-size;
$selection-group--sm__button_padding-horizontal: 6px;
$selection-group--sm__button_padding: $selection-group--sm__button_padding-vertical
    $selection-group--sm__button_padding-horizontal;
$selection-group--sm__prefix_margin: grid-type-unit();

/*  Type
    ========================================================================== */

/* Checkbox */
$selection-group--checkbox_spacing: grid-type-unit();

/* Radio */
$selection-group--radio_border-size: $border-width-light;

$selection-group--radio-size-md_spacing: 2px;
$selection-group--radio-size-sm_spacing: 1px;

$selection-group--radio__button_min-size: $component-size-base -
    ($selection-group--radio-size-md_spacing + $selection-group--radio_border-size) * 2;
$selection-group--radio__button_min-width: $selection-group--radio__button_min-size;
$selection-group--radio__button_min-height: $selection-group--radio__button_min-size;
$selection-group--radio__button_padding-vertical: (
        ($selection-group--radio__button_min-size - $selection-group__button_line-height) * 0.5
    ) -
    $selection-group--radio_border-size;
$selection-group--radio__button_padding: $selection-group--radio__button_padding-vertical
    $selection-group__button_padding-horizontal;

$selection-group--radio-size-sm__button_min-size: $component-size-sm -
    ($selection-group--radio-size-sm_spacing + $selection-group--radio_border-size) * 2;
$selection-group--radio-size-sm__button_padding-vertical: (
        ($selection-group--radio-size-sm__button_min-size - $selection-group__button_line-height) * 0.5
    ) -
    $selection-group--radio_border-size;

$selection-group--radio-size-sm__button_padding: $selection-group--radio-size-sm__button_padding-vertical
    $selection-group--sm__button_padding-horizontal;

/*  States
    ========================================================================== */

/* active */
$selection-group__button--is-active_border-color: $color-selected;
$selection-group__button--is-active_bg-color: $color-selected;
$selection-group__button--is-active_color: $color-white;

/* disabled */
$selection-group__button-disabled_border-color: color-disabled($color-black-100);
$selection-group__button-disabled_bg-color: color-disabled($color-black-100);
$selection-group__button-disabled_color: color-disabled($color-black-100);

/*  Theme
    ========================================================================== */

/* LIGHT BLUE */

/* disabled */
$selection-group--theme-on-light-blue__button-disabled_border-color: color-disabled($color-blue-100);

/* DARK BLUE */
$selection-group--theme-on-dark-blue__button_bg-color-highlighted: rgba($color-white, 0.08);
$selection-group--theme-on-dark-blue_border-color: rgba($color-white, 0.24);

/* --checkbox hover */
$selection-group--theme-on-dark-blue__button--checkbox-hover_border-color: rgba(
    $color-white,
    $color-opacity-high
);

/* --checkbox checked & pressed  */
$selection-group--theme-on-dark-blue__button--checkbox-checked-pressed_border-color: rgba(
    $color-white,
    $color-opacity-high
);

/* --radio pressed */
$selection-group--theme-on-dark-blue__button--radio-pressed_bg-color: rgba($color-white, 0.12);

/* input disabled */
$selection-group--theme-on-dark-blue__button-disabled_border-color: color-disabled($color-dark-blue);
$selection-group--theme-on-dark-blue__button-disabled_bg-color: color-disabled($color-dark-blue);
$selection-group--theme-on-dark-blue__button-disabled_color: color-disabled($color-text-on-dark);

/*  Legacy
    ========================================================================== */

$selection-group__button_bg-color: $color-white;
