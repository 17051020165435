/*  ==========================================================================
    Table component
    ========================================================================== */

/* Full width first column */
$table--is-full-width-first-column_visual-offset: grid-type-unit(1);

/* Item */
$table__cell-item_offset: grid-type-unit();

/* Z-index */
$table--sticky-header__cell--header_z-index: $z-index-sticky + $z-index-above;
$table--sticky-column__cell--header-first_z-index: $z-index-sticky + $z-index-focus;
$table--sticky-column__cell_z-index: $z-index-sticky;
$table--sticky-column__cell_z-index-focus: $z-index-sticky + $z-index-focus;

/* Colors */
$table__background: $color-white;
$table__header_color: $color-text-soft;
$table_border-color: $color-black-100;
$table__color-focus: $color-focus;

/* Contextual */
$table__radio-checkbox_padding: grid-unit(1);
$table__radio-checkbox_padding-dense: grid-type-unit(1);
$table__radio-checkbox_margin: grid-type-unit(3);
$table__form-input-group_padding-h: grid-type-unit(1);

/* Border */
$table_border-spacing: 0 grid-type-unit(1);
$table__border_width: $border-width-light;

/* Cell */
$table__cell_padding-horizontal: grid-type-unit(3);
$table__cell_padding-vertical: calc(grid-type-unit(3) - $table__border_width);
$table__cell_border-radius: $border-radius;
$table__cell_font-weight: $font-weight-regular;
$table__cell--select_width: grid-unit(2);

/* Cell variants */
$table__cell--header_padding-vertical: grid-unit();
$table__cell--interactive_padding: (
        ($line-height-computed + ($table__cell_padding-vertical * 2)) - $component-size-base
    ) *
    0.5;
$table__cell--interactive-sm_padding: (
        ($line-height-computed + ($table__cell_padding-vertical * 2)) - $component-size-sm
    ) *
    0.5;
$table__cell--highlight_color: $color-blue-50;
$table__cell--highlight_color-hover: $color-blue-100;
$table__cell--highlight_color-selected: $color-blue-100;

/* Cell editable */
$table--editable__cell_padding-horizontal: grid-type-unit(3);
$table--editable--density-xs__cell_padding-horizontal: grid-type-unit(1);
$table--editable--density-sm__cell_padding-horizontal: grid-unit(1);

/* Cell density-xs */
$table--density-xs__cell--min-height: grid-type-unit(7);
$table--density-xs__cell_padding-horizontal: grid-type-unit(1);
$table--density-xs__cell_padding-vertical: calc(grid-type-unit(1) - $table__border_width);
$table--density-xs__cell--interactive_padding: (
        ($line-height-computed + ($table--density-xs__cell_padding-vertical * 2)) - $component-size-base
    ) *
    0.5;
$table--density-xs__cell--interactive-sm_padding: (
        ($line-height-computed + ($table--density-xs__cell_padding-vertical * 2)) - $component-size-sm
    ) *
    0.5;

/* Cell density-sm */
$table--density-sm__cell--min-height: grid-type-unit(7);
$table--density-sm__cell_padding-horizontal: grid-type-unit(2);
$table--density-sm__cell_padding-vertical: calc(grid-type-unit(1.5) - $table__border_width);
$table--density-sm__cell--interactive_padding: (
        ($line-height-computed + ($table--density-sm__cell_padding-vertical * 2)) - $component-size-base
    ) *
    0.5;
$table--density-sm__cell--interactive-sm_padding: (
        ($line-height-computed + ($table--density-sm__cell_padding-vertical * 2)) - $component-size-sm
    ) *
    0.5;

/* Row clickable */
$table__row--clickable-z-index: $z-index-sticky + 1;

/* Placeholder */
%table__cell--editable_control {
    width: 100%;
    border: 0;
    border-radius: 0;
    line-height: $line-height-md-computed;
    text-decoration: $color-text-soft underline;
    background-color: transparent;
    box-shadow: none;

    &:hover {
        text-decoration-color: $color-text;
    }

    &:focus {
        text-decoration: none;
    }
}

%table__cell--editable_options {
    height: $component-size-base;
    padding: $table__radio-checkbox_padding $table--editable__cell_padding-horizontal;
    white-space: nowrap;
}

%table__cell--header-editable_options {
    height: auto;
    padding: $table__radio-checkbox_padding-dense $table--editable__cell_padding-horizontal;
    white-space: nowrap;
}
