// Do not edit directly.
// This file is generated using Style Dictionary.

$priva-color-white: #ffffff !default;
$priva-color-black: #000000 !default;
$priva-color-gray-100: #fcfcfc !default;
$priva-color-gray-200: #f4f4f4 !default;
$priva-color-gray-300: #e4e4e4 !default;
$priva-color-gray-400: #dbdbdb !default;
$priva-color-gray-500: #cdcdcd !default;
$priva-color-gray-600: #b8b8b8 !default;
$priva-color-gray-700: #9b9b9b !default;
$priva-color-gray-800: #717171 !default;
$priva-color-gray-900: #515151 !default;
$priva-color-gray-1000: #333333 !default;
$priva-color-gray-1100: #27282b !default;
$priva-color-gray-1200: #1c1c1c !default;
$priva-color-sapphire-100: #fafdff !default;
$priva-color-sapphire-200: #edf5fd !default;
$priva-color-sapphire-300: #d2e6fa !default;
$priva-color-sapphire-400: #bddbf9 !default;
$priva-color-sapphire-500: #a2ccf6 !default;
$priva-color-sapphire-600: #7dbaf2 !default;
$priva-color-sapphire-700: #4599ed !default;
$priva-color-sapphire-800: #1266ba !default;
$priva-color-sapphire-900: #0e5092 !default;
$priva-color-sapphire-1000: #09355d !default;
$priva-color-sapphire-1100: #072644 !default;
$priva-color-sapphire-1200: #051d34 !default;
$priva-color-stone-100: #fcfcfd !default;
$priva-color-stone-200: #f3f4f8 !default;
$priva-color-stone-300: #e1e4ed !default;
$priva-color-stone-400: #d3d7e4 !default;
$priva-color-stone-500: #bfc5d9 !default;
$priva-color-stone-600: #a6afc9 !default;
$priva-color-stone-700: #7f8db3 !default;
$priva-color-stone-800: #515d86 !default;
$priva-color-stone-900: #3d4766 !default;
$priva-color-stone-1000: #262d40 !default;
$priva-color-stone-1100: #1d2130 !default;
$priva-color-stone-1200: #181c27 !default;
$priva-color-skyblue-100: #fafeff !default;
$priva-color-skyblue-200: #e5fcff !default;
$priva-color-skyblue-300: #ccf9ff !default;
$priva-color-skyblue-400: #b8f7ff !default;
$priva-color-skyblue-500: #99f3ff !default;
$priva-color-skyblue-600: #70efff !default;
$priva-color-skyblue-700: #4fdbee !default;
$priva-color-skyblue-800: #00b5ce !default;
$priva-color-skyblue-900: #0091a3 !default;
$priva-color-skyblue-1000: #146570 !default;
$priva-color-skyblue-1100: #00444d !default;
$priva-color-skyblue-1200: #003238 !default;
$priva-color-steel-100: #fcfdfd !default;
$priva-color-steel-200: #f2f6f7 !default;
$priva-color-steel-300: #e3eaee !default;
$priva-color-steel-400: #d3dfe4 !default;
$priva-color-steel-500: #c0d1d8 !default;
$priva-color-steel-600: #a6bec9 !default;
$priva-color-steel-700: #81a3b1 !default;
$priva-color-steel-800: #4f7180 !default;
$priva-color-steel-900: #3c5762 !default;
$priva-color-steel-1000: #324852 !default;
$priva-color-steel-1100: #1d2a2f !default;
$priva-color-steel-1200: #151f23 !default;
$priva-color-honey-100: #fffdfa !default;
$priva-color-honey-200: #fff6eb !default;
$priva-color-honey-300: #ffead1 !default;
$priva-color-honey-400: #ffdfb8 !default;
$priva-color-honey-500: #ffd199 !default;
$priva-color-honey-600: #ffbf70 !default;
$priva-color-honey-700: #ffa333 !default;
$priva-color-honey-800: #cc7000 !default;
$priva-color-honey-900: #995400 !default;
$priva-color-honey-1000: #663800 !default;
$priva-color-honey-1100: #4d2a00 !default;
$priva-color-honey-1200: #381f00 !default;
$priva-color-emerald-100: #fbfefb !default;
$priva-color-emerald-200: #f1faf0 !default;
$priva-color-emerald-300: #def3dd !default;
$priva-color-emerald-400: #cbedca !default;
$priva-color-emerald-500: #b5e5b3 !default;
$priva-color-emerald-600: #97da95 !default;
$priva-color-emerald-700: #6bca68 !default;
$priva-color-emerald-800: #389735 !default;
$priva-color-emerald-900: #2b7529 !default;
$priva-color-emerald-1000: #1c4b1b !default;
$priva-color-emerald-1100: #153914 !default;
$priva-color-emerald-1200: #0f2a0e !default;
$priva-color-sunflower-100: #fefdfa !default;
$priva-color-sunflower-200: #fcf7ed !default;
$priva-color-sunflower-300: #f9edd7 !default;
$priva-color-sunflower-400: #f6e3c1 !default;
$priva-color-sunflower-500: #f2d8a6 !default;
$priva-color-sunflower-600: #edc882 !default;
$priva-color-sunflower-700: #e5b04b !default;
$priva-color-sunflower-800: #b27c1a !default;
$priva-color-sunflower-900: #865e13 !default;
$priva-color-sunflower-1000: #593f0d !default;
$priva-color-sunflower-1100: #432f09 !default;
$priva-color-sunflower-1200: #322107 !default;
$priva-color-terracotta-100: #fefbfb !default;
$priva-color-terracotta-200: #fcf1ee !default;
$priva-color-terracotta-300: #f8dfd9 !default;
$priva-color-terracotta-400: #f3cec3 !default;
$priva-color-terracotta-500: #eeb8aa !default;
$priva-color-terracotta-600: #e89c87 !default;
$priva-color-terracotta-700: #de7254 !default;
$priva-color-terracotta-800: #ab3f21 !default;
$priva-color-terracotta-900: #9b391e !default;
$priva-color-terracotta-1000: #551f11 !default;
$priva-color-terracotta-1100: #40180c !default;
$priva-color-terracotta-1200: #2f1209 !default;
$priva-color-blossom-100: #fefbfb !default;
$priva-color-blossom-200: #fbf1f1 !default;
$priva-color-blossom-300: #f6dbda !default;
$priva-color-blossom-400: #f0c7c7 !default;
$priva-color-blossom-500: #eab0ae !default;
$priva-color-blossom-600: #e18f8c !default;
$priva-color-blossom-700: #d5605d !default;
$priva-color-blossom-800: #a22e2a !default;
$priva-color-blossom-900: #7a231f !default;
$priva-color-blossom-1000: #511715 !default;
$priva-color-blossom-1100: #3d1110 !default;
$priva-color-blossom-1200: #2d0d0b !default;
$priva-color-amethyst-100: #fefbfe !default;
$priva-color-amethyst-200: #faf2fa !default;
$priva-color-amethyst-300: #f3ddf4 !default;
$priva-color-amethyst-400: #ebcaed !default;
$priva-color-amethyst-500: #e3b3e5 !default;
$priva-color-amethyst-600: #d895db !default;
$priva-color-amethyst-700: #c867cb !default;
$priva-color-amethyst-800: #943597 !default;
$priva-color-amethyst-900: #702772 !default;
$priva-color-amethyst-1000: #491a4c !default;
$priva-color-amethyst-1100: #371439 !default;
$priva-color-amethyst-1200: #290e2a !default;
$priva-color-crimson-100: #fffafa !default;
$priva-color-crimson-200: #ffebeb !default;
$priva-color-crimson-300: #ffd3d1 !default;
$priva-color-crimson-400: #ffb9b8 !default;
$priva-color-crimson-500: #ff9b99 !default;
$priva-color-crimson-600: #ff7370 !default;
$priva-color-crimson-700: #f5403d !default;
$priva-color-crimson-800: #cc0300 !default;
$priva-color-crimson-900: #990300 !default;
$priva-color-crimson-1000: #660200 !default;
$priva-color-crimson-1100: #4d0100 !default;
$priva-color-crimson-1200: #380100 !default;
$priva-color-amber-100: #fffdfa !default;
$priva-color-amber-200: #fff8eb !default;
$priva-color-amber-300: #fff0d1 !default;
$priva-color-amber-400: #ffe7b8 !default;
$priva-color-amber-500: #ffdd99 !default;
$priva-color-amber-600: #ffcf70 !default;
$priva-color-amber-700: #ffbb33 !default;
$priva-color-amber-800: #cc8800 !default;
$priva-color-amber-900: #996600 !default;
$priva-color-amber-1000: #664400 !default;
$priva-color-amber-1100: #4d3300 !default;
$priva-color-amber-1200: #382500 !default;
$priva-color-forrest-100: #fbfefc !default;
$priva-color-forrest-200: #eefcf2 !default;
$priva-color-forrest-300: #d9f8e2 !default;
$priva-color-forrest-400: #c3f3d1 !default;
$priva-color-forrest-500: #aaeebe !default;
$priva-color-forrest-600: #87e8a4 !default;
$priva-color-forrest-700: #54de7d !default;
$priva-color-forrest-800: #21ab4a !default;
$priva-color-forrest-900: #198037 !default;
$priva-color-forrest-1000: #115525 !default;
$priva-color-forrest-1100: #0c401c !default;
$priva-color-forrest-1200: #092f14 !default;
$priva-color-deepsea-100: #fafbff !default;
$priva-color-deepsea-200: #ebf0fe !default;
$priva-color-deepsea-300: #d3ddfd !default;
$priva-color-deepsea-400: #bacbfd !default;
$priva-color-deepsea-500: #9cb4fc !default;
$priva-color-deepsea-600: #7597fa !default;
$priva-color-deepsea-700: #4471f8 !default;
$priva-color-deepsea-800: #0737c5 !default;
$priva-color-deepsea-900: #052b99 !default;
$priva-color-deepsea-1000: #031b63 !default;
$priva-color-deepsea-1100: #03154a !default;
$priva-color-deepsea-1200: #020f36 !default;
$color-white: hsl(0, 0%, 100%) !default;
$color-grayscale-50: hsl(0, 0%, 96%) !default;
$color-grayscale-100: hsl(0, 0%, 91%) !default;
$color-grayscale-200: hsl(0, 0%, 87%) !default;
$color-grayscale-300: hsl(0, 0%, 81%) !default;
$color-grayscale-400: hsl(0, 0%, 65%) !default;
$color-grayscale-500: hsl(0, 0%, 53%) !default;
$color-grayscale-600: hsl(0, 0%, 44%) !default;
$color-grayscale-700: hsl(0, 0%, 36%) !default;
$color-grayscale-800: hsl(0, 0%, 27%) !default;
$color-grayscale-900: hsl(0, 0%, 22%) !default;
$color-grayscale-1000: hsl(0, 0%, 15%) !default;
$color-black-50: hsl(240, 5%, 96%) !default;
$color-black-100: hsl(210, 5%, 91%) !default;
$color-black-200: hsl(220, 4%, 87%) !default;
$color-black-300: hsl(225, 4%, 81%) !default;
$color-black-400: hsl(220, 5%, 65%) !default;
$color-black-500: hsl(218, 5%, 54%) !default;
$color-black-600: hsl(224, 5%, 45%) !default;
$color-black-700: hsl(220, 5%, 37%) !default;
$color-black-800: hsl(223, 5%, 28%) !default;
$color-black-900: hsl(220, 5%, 23%) !default;
$color-black-1000: hsl(225, 5%, 16%) !default;
$color-blue-50: hsl(203, 62%, 96%) !default;
$color-blue-100: hsl(200, 57%, 91%) !default;
$color-blue-200: hsl(200, 56%, 86%) !default;
$color-blue-300: hsl(200, 52%, 79%) !default;
$color-blue-400: hsl(200, 51%, 61%) !default;
$color-blue-500: hsl(200, 49%, 49%) !default;
$color-blue-600: hsl(200, 47%, 41%) !default;
$color-blue-700: hsl(200, 45%, 33%) !default;
$color-blue-800: hsl(200, 43%, 25%) !default;
$color-blue-900: hsl(200, 45%, 21%) !default;
$color-blue-1000: hsl(199, 47%, 14%) !default;
$color-green-blue-50: hsl(188, 80%, 94%) !default;
$color-green-blue-100: hsl(187, 84%, 85%) !default;
$color-green-blue-200: hsl(187, 90%, 76%) !default;
$color-green-blue-300: hsl(187, 95%, 58%) !default;
$color-green-blue-400: hsl(187, 100%, 40%) !default;
$color-green-blue-500: hsl(187, 95%, 34%) !default;
$color-green-blue-600: hsl(187, 90%, 29%) !default;
$color-green-blue-700: hsl(187, 85%, 24%) !default;
$color-green-blue-800: hsl(187, 79%, 19%) !default;
$color-green-blue-900: hsl(187, 75%, 16%) !default;
$color-green-blue-1000: hsl(188, 71%, 11%) !default;
$color-teal-50: hsl(171, 68%, 94%) !default;
$color-teal-100: hsl(173, 67%, 86%) !default;
$color-teal-200: hsl(173, 67%, 76%) !default;
$color-teal-300: hsl(173, 67%, 65%) !default;
$color-teal-400: hsl(173, 67%, 43%) !default;
$color-teal-500: hsl(173, 67%, 36%) !default;
$color-teal-600: hsl(173, 67%, 30%) !default;
$color-teal-700: hsl(173, 67%, 24%) !default;
$color-teal-800: hsl(173, 68%, 18%) !default;
$color-teal-900: hsl(173, 66%, 15%) !default;
$color-teal-1000: hsl(173, 66%, 10%) !default;
$color-purple-50: hsl(283, 41%, 97%) !default;
$color-purple-100: hsl(284, 41%, 93%) !default;
$color-purple-200: hsl(286, 38%, 89%) !default;
$color-purple-300: hsl(287, 39%, 84%) !default;
$color-purple-400: hsl(286, 39%, 70%) !default;
$color-purple-500: hsl(286, 39%, 60%) !default;
$color-purple-600: hsl(286, 39%, 51%) !default;
$color-purple-700: hsl(286, 39%, 42%) !default;
$color-purple-800: hsl(286, 39%, 32%) !default;
$color-purple-900: hsl(286, 39%, 26%) !default;
$color-purple-1000: hsl(287, 39%, 18%) !default;
$color-green-50: hsl(136, 73%, 94%) !default;
$color-green-100: hsl(137, 73%, 86%) !default;
$color-green-200: hsl(137, 73%, 77%) !default;
$color-green-300: hsl(137, 73%, 65%) !default;
$color-green-400: hsl(137, 73%, 43%) !default;
$color-green-500: hsl(137, 73%, 36%) !default;
$color-green-600: hsl(137, 72%, 30%) !default;
$color-green-700: hsl(137, 72%, 24%) !default;
$color-green-800: hsl(137, 72%, 18%) !default;
$color-green-900: hsl(137, 74%, 15%) !default;
$color-green-1000: hsl(137, 74%, 10%) !default;
$color-orange-50: hsl(38, 100%, 94%) !default;
$color-orange-100: hsl(38, 100%, 87%) !default;
$color-orange-200: hsl(38, 100%, 79%) !default;
$color-orange-300: hsl(38, 100%, 69%) !default;
$color-orange-400: hsl(38, 100%, 45%) !default;
$color-orange-500: hsl(38, 100%, 37%) !default;
$color-orange-600: hsl(38, 100%, 31%) !default;
$color-orange-700: hsl(38, 100%, 25%) !default;
$color-orange-800: hsl(38, 100%, 19%) !default;
$color-orange-900: hsl(38, 100%, 16%) !default;
$color-orange-1000: hsl(38, 100%, 11%) !default;
$color-red-50: hsl(350, 86%, 97%) !default;
$color-red-100: hsl(351, 87%, 94%) !default;
$color-red-200: hsl(353, 83%, 91%) !default;
$color-red-300: hsl(352, 85%, 87%) !default;
$color-red-400: hsl(352, 84%, 74%) !default;
$color-red-500: hsl(352, 85%, 62%) !default;
$color-red-600: hsl(352, 84%, 48%) !default;
$color-red-700: hsl(352, 84%, 39%) !default;
$color-red-800: hsl(352, 84%, 30%) !default;
$color-red-900: hsl(352, 84%, 25%) !default;
$color-red-1000: hsl(352, 84%, 18%) !default;
