/*  ==========================================================================
    Combobox
    ========================================================================== */

$combobox_height--size-md: $form-control-height--size-md;
$combobox_height--size-sm: $form-control-height--size-sm;
$combobox_inner-height: $line-height-computed;

/* dependant variables */
$combobox__clear_width--size-md: grid-unit(3);
$combobox__clear_width--size-sm: grid-unit(2);

/* select */
$combobox__select_padding-v--size-md: (grid-unit() - $form-control-border-w);
$combobox__select_padding-right--size-md: grid-unit(4);
$combobox__select_padding-left--size-md: grid-type-unit(3);
$combobox__select_padding--size-md: $combobox__select_padding-v--size-md
    $combobox__select_padding-right--size-md $combobox__select_padding-v--size-md
    $combobox__select_padding-left--size-md;

$combobox__select_padding-v--size-sm: (grid-type-unit() - $form-control-border-w);
$combobox__select_padding-right--size-sm: grid-unit(3);
$combobox__select_padding-left--size-sm: grid-unit();
$combobox__select_padding--size-sm: $combobox__select_padding-v--size-sm
    $combobox__select_padding-right--size-sm $combobox__select_padding-v--size-sm
    $combobox__select_padding-left--size-sm;

/* input */
$combobox__input-wrapper_padding-left--size-md: $combobox__select_padding-left--size-md;
$combobox__input-wrapper_padding-right--size-md: $combobox__clear_width--size-md +
    $form-control-padding-h--size-md;
$combobox__input-wrapper_padding-left--size-sm: $combobox__select_padding-left--size-sm;
$combobox__input-wrapper_padding-right--size-sm: $combobox__clear_width--size-sm +
    $form-control-padding-h--size-sm;
$combobox__input_min-width: grid-unit(12);
$combobox__input_min-height--size-md: $combobox_height--size-md - ($form-control-border-w * 2);
$combobox__input_min-height--size-sm: $combobox_height--size-sm - ($form-control-border-w * 2);
$combobox__input-search-icon_width--size-md: grid-unit(2);
$combobox__input-search-icon_width--size-sm: grid-unit(2);
$combobox__input-control_padding-v--size-md: ($form-control-padding-v--size-md - $form-control-border-w);
$combobox__input-control_padding-h--size-md: grid-unit();
$combobox__input-control_padding--size-md: $combobox__input-control_padding-v--size-md 0;
$combobox__input-control_padding-v--size-sm: (grid-type-unit() - $form-control-border-w);
$combobox__input-control_padding-h--size-sm: grid-type-unit();
$combobox__input-control_padding--size-sm: $combobox__input-control_padding-v--size-sm 0;

/* clear */
$combobox__clear_height--size-md: $combobox_height--size-md;
$combobox__clear_height--size-sm: $combobox_height--size-sm;
$combobox__clear_offset: grid-unit(-1);

/* arrow touch target */
$combobox__arrow_size--size-md: $combobox_height--size-md;
$combobox__arrow_size--size-sm: $combobox_height--size-sm;

/* loader */
$combobox__loader_size--size-md: $icon-font-size-md;
$combobox__loader_size--size-sm: $form-control-font-size--size-sm;
$combobox__loader_border-width--size-md: $border-width-regular;
$combobox__loader_margin-h--size-md: (
        $form-control-height--size-md - $combobox__loader_border-width--size-md -
            $combobox__loader_size--size-md
    ) *
    0.5 - $form-control-border-w;
$combobox__loader_margin-h--size-sm: (
        $form-control-height--size-sm - $combobox__loader_border-width--size-md -
            $combobox__loader_size--size-sm
    ) *
    0.5 - $form-control-border-w;

/* dropdown */
$combobox__dropdown_margin: grid-type-unit() 0;
$combobox__dropdown_padding: grid-unit();
$combobox__dropdown_max-height: grid-unit(30);

/* option */
$combobox__option_padding: grid-unit() - $border-width-light;

/* option group */
$combobox__dropdown-option-group-label_spacing: grid-type-unit();
$combobox__dropdown-option-group_spacing-between: grid-unit();

/*  Multi select
    ========================================================================== */

$combobox--multiselect__value_spacing: grid-type-unit();
$combobox--multiselect__value-container-non-value-child_margin-bottom: $combobox--multiselect__value_spacing;
$combobox--multiselect__value_margin: 0 $combobox--multiselect__value_spacing
    $combobox--multiselect__value_spacing 0;
$combobox--multiselect__input-margin-left: $combobox--multiselect__value_spacing * 2;

/*  Custom templates
    ========================================================================== */

/* label */
$combobox__label-icon_spacing--size-md: grid-unit();
$combobox__label-icon_spacing--size-sm: grid-type-unit();

/* label */
$combobox__option-icon_size: grid-unit(5);
$combobox__option-icon_spacing: grid-unit();

/*  Legacy
    ========================================================================== */
$combobox_width: $form-control-width;
$combobox_height: $form-control-height--size-md;
$combobox__clear_height: $combobox_height;
$combobox__arrow_size: $combobox_height;
$combobox__input-wrapper_padding-left: $combobox__input-wrapper_padding-left--size-md;
$combobox__input-wrapper_padding-right: $combobox__input-wrapper_padding-right--size-md;
$combobox__select_padding-v: (grid-unit() - $form-control-border-w);
$combobox__select_padding-right: grid-unit(4);
$combobox__select_padding-left: grid-type-unit(3);
$combobox__select_padding: $combobox__select_padding-v $combobox__select_padding-right
    $combobox__select_padding-v $combobox__select_padding-left;
$combobox__input-control_padding-h: $combobox__input-control_padding-h--size-md;
$combobox__input-control_padding-v: $combobox__input-control_padding-v--size-md;
$combobox__label-icon_spacing: $combobox__label-icon_spacing--size-md;
$combobox__clear_width: $combobox__clear_width--size-md;
$combobox__input-search-icon_width: $combobox__input-search-icon_width--size-md;
