/*  ==========================================================================
    Tag
    ========================================================================== */

$tag_border-width: $border-width-light;
$tag_border-radius: $border-radius;

/*  Icon
    ========================================================================== */
$tag__icon_offset: grid-type-unit();

/*  Label
    ========================================================================== */
$tag__label_font-weight: $font-weight-regular;
$tag__label_font-size: $font-size-sm;
$tag__label_line-height: 1;

/*  Size
    ========================================================================== */

/* xs */
$tag_height--size-xs: grid-type-unit(5);
$tag_height-inner--size-xs: $tag_height--size-xs - ($tag_border-width * 2);
$tag_padding-h--size-xs: grid-unit(1);
$tag_padding-v--size-xs: 0;
$tag_padding--size-xs: $tag_padding-v--size-xs $tag_padding-h--size-xs;
$tag_padding-h--density-sm--size-xs: grid-type-unit(1);
$tag_padding--density-sm-size-xs: $tag_padding-v--size-xs $tag_padding-h--density-sm--size-xs;

$tag__label_padding-v--size-xs: grid-type-unit(0.5);

$tag__trigger_size--size-xs: $tag_height--size-xs;
$tag__trigger_margin-v--size-xs: ($tag_height_inner--size-xs - $tag_height--size-xs) * 0.5;
$tag__trigger_margin--size-xs: $tag__trigger_margin-v--size-xs -#{($tag_padding-h--size-xs * 0.5) + 2px}
    $tag__trigger_margin-v--size-xs 2px;
$tag__trigger_margin--density-sm-size-xs: $tag__trigger_margin-v--size-xs
    (-$tag_padding-h--density-sm--size-xs) $tag__trigger_margin-v--size-xs 0;

/* sm: default */
$tag_height--size-sm: $component-size-sm;
$tag_height-inner--size-sm: $tag_height--size-sm - ($tag_border-width * 2);
$tag_padding-h--size-sm: grid-unit(1);
$tag_padding-v--size-sm: 0;
$tag_padding--size-sm: $tag_padding-v--size-sm $tag_padding-h--size-sm;
$tag_padding-h--density-sm--size-sm: grid-type-unit(1);
$tag_padding--density-sm-size-sm: $tag_padding-v--size-sm $tag_padding-h--density-sm--size-sm;

$tag__label_padding-v--size-sm: grid-type-unit(1);

$tag__trigger_size--size-sm: $tag_height--size-sm;
$tag__trigger_margin-v--size-sm: ($tag_height_inner--size-sm - $tag_height--size-sm) * 0.5;
$tag__trigger_margin--size-sm: $tag__trigger_margin-v--size-sm -#{($tag_padding-h--size-sm * 0.5) + 2px}
    $tag__trigger_margin-v--size-sm grid-type-unit(1);
$tag__trigger_margin--density-sm-size-sm: $tag__trigger_margin-v--size-sm
    (-$tag_padding-h--density-sm--size-sm) $tag__trigger_margin-v--size-sm 0;

/*  Mixin size map
    ========================================================================== */
$tag-sizes: (
    xs: (
        size: $tag__trigger_size--size-xs,
        padding-h: $tag_padding-h--size-xs,
        padding: $tag_padding--size-xs,
        innersize: $tag_height-inner--size-xs,
        label: (
            padding: $tag__label_padding-v--size-xs 0,
        ),
        trigger: (
            size: $tag__trigger_size--size-xs,
            margin: $tag__trigger_margin--size-xs,
        ),
    ),
    sm: (
        size: $tag__trigger_size--size-sm,
        padding-h: $tag_padding-h--size-sm,
        padding: $tag_padding--size-sm,
        innersize: $tag_height-inner--size-sm,
        label: (
            padding: $tag__label_padding-v--size-sm 0,
        ),
        trigger: (
            size: $tag__trigger_size--size-sm,
            margin: $tag__trigger_margin--size-sm,
        ),
    ),
);
